<template>
    <div id="home">
        <inc_header></inc_header>

        <el-carousel :interval="5000" arrow="hover">
            <el-carousel-item v-for="is in indexSlides" :key="is.slideId">
                <router-link v-if="is.hrefPc" :to="is.hrefPc"><el-image :src="is.srcPc" fit="cover"></el-image></router-link>
                <el-image v-else :src="is.srcPc" fit="cover"></el-image>
            </el-carousel-item>
        </el-carousel>

        <inc_footer></inc_footer>
    </div>
</template>

<style scoped>
    #home>>>.el-carousel__container { width:100%; height:810px;}
    #home>>>.el-carousel__container .el-image { width:100%; height:100%; }
    #home>>>.el-carousel__indicators--horizontal { bottom:50px; }
</style>

<script>
    import inc_header from "./../components/inc_header.vue"
    import inc_footer from "./../components/inc_footer.vue"

    export default {
        name: "home",
        components: {
            inc_header,
            inc_footer
        },
        data() {
            return {
                indexSlides: []  // 首页幻灯片
            }
        },
        created() {
            let that = this;
            // 获取首页幻灯片
            this.$axios.post(this.$global.config.serverApi+'/index_slide.php',{ action:"list", status:"open", isPc:1, page:0, rowsPerPage:0 }).then( function(response) {
                if (response.data.complete === true) {
                    that.indexSlides = response.data.indexSlides;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });
        },
    }
</script>
